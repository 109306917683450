.accord-principale{
    background-color: #3e5165;
}

a{
    cursor: pointer;
}

.accord-principale.menue{
    padding: 0;
    list-style: none;
    width: 400px;
    margin: 20px auto;
    font-family: 'Century Gothic'; 
    box-shadow: 0px 0px 25px #00000070;
    clear: both;
    display: table;
    margin-bottom:10px;
}

.menue.liste{
    font-size: 14px;
       border-bottom:1px solid #324252;
       position: relative;
       width: 100%;
       box-sizing: border-box;
       height: 50px;
       vertical-align: sub;
       background-color: #3e5165;
       clear: both;
}

.liste::after{
    content: "\f107";
    font-family:FontAwesome;
    position: absolute;
    right: 17px;
    top: 17px;
    padding: 0px 5px;
    color:#fff;
}

.liste::before{
    content: '\f07b';
    font-family:FontAwesome;
    position: absolute;
    left: 17px;
    top: 17px;
    padding: 0px 5px;
    color:#fff;  
}

.liste a {
    text-decoration: none;
    color: #fff;
    padding: 17px 0px 17px 45px;
    display: block;
    height: 100%;
    box-sizing: border-box;
}

.liste a:hover{
    background-color: #324252;
    transition:  300ms all;
    color: #09fbd2;
}

.liste .items{
    height: 0px;
    overflow: hidden;
}

.items a{
    padding:17px;
}

.items a:hover{
    background-color: #3f5d79;
    color:#fff;
    transition:  300ms all;
}

.liste:last-child{
    border-bottom:none;
}

.activeb::after{
    content: "\f106";
    font-family:FontAwesome;
    position: absolute;
    right: 17px;
    top: 17px;
    padding: 0px 5px;
    color:#fff;
}

.activeb::before{
    content: '\f07c';
    font-family:FontAwesome;
    position: absolute;
    left: 17px;
    top: 17px;
    padding: 0px 5px;
    color:#fff;
}

.activeb .items{
    display: block;
    background: #23313f;
    padding: 0px;
    height: auto;
    color:#fff;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all 200ms;
    clear: both;
    float: left;
    width: 100%;
}

.activeb .items li{
    padding:0px;
    border-bottom:1px solid #324252;
    list-style: none;
}

.activeb .items li:last-child{
    border-color:transparent;
    padding-bottom: 0px;
}

.activeb a{
    color:#46efa4;
    text-transform: uppercase;
    font-weight: bold;
}

.activeb .liste{
    background: #697d92;
}

.activeb .liste a {
    padding: 17px 0px 17px 45px;
}